var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary"},[_vm._v(" Quotation ")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-0",attrs:{"lg":"8"}},[_c('div',{staticClass:"d-lg-flex align-items-center justify-content-center"},[_c('Lottie',{staticClass:"h-100",attrs:{"options":{
            animationData: _vm.login,
          }}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto d-flex flex-column",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('Lottie',{staticClass:"d-block d-lg-none",attrs:{"options":{
            animationData: _vm.login,
          }}}),_c('b-card-title',{staticClass:"font-weight-bold mb-1 mt-2",attrs:{"title-tag":"h2"}},[_vm._v(" Selamat datang! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Silahkan login terlebih dahulu ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Nomor HP","label-for":"login-phone"}},[_c('validation-provider',{attrs:{"name":"Nomor HP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null,attrs:{"prepend":"+62"}},[_c('b-form-input',{attrs:{"id":"login-phone","type":"number","name":"login-phone","placeholder":"85288898889","state":errors.length > 0 ? false:null},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.status)?_c('div',{staticClass:"d-flex mb-2"},[_c('small',{staticClass:"text-danger mx-auto"},[_vm._v(_vm._s(_vm.status))])]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary","block":""},on:{"click":function($event){_vm.loading ? null : _vm.startLogin()}}},[(_vm.loading)?_c('b-spinner',{attrs:{"variant":"light","small":""}}):_c('span',[_vm._v("Login")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }