<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary">
          Quotation
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="d-lg-flex align-items-center justify-content-center">
          <Lottie
            :options="{
              animationData: login,
            }"
            class="h-100"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto d-flex flex-column"
        >
          <Lottie
            class="d-block d-lg-none"
            :options="{
              animationData: login,
            }"
          />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-2"
          >
            Selamat datang! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Silahkan login terlebih dahulu
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Nomor HP"
                label-for="login-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor HP"
                  rules="required"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                    prepend="+62"
                  >
                    <b-form-input
                      id="login-phone"
                      v-model="phone"
                      type="number"
                      name="login-phone"
                      placeholder="85288898889"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div
                v-if="status"
                class="d-flex mb-2"
              >
                <small class="text-danger mx-auto">{{ status }}</small>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                block
                @click="loading ? null : startLogin()"
              >
                <b-spinner
                  v-if="loading"
                  variant="light"
                  small
                />
                <span v-else>Login</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Lottie from 'vue-lottie'
import login from '@/assets/images/lottie/login.json'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    Lottie,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      login,
      status: '',
      password: '',
      phone: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    phone: {
      handler(data) {
        if (data.toString().startsWith('62') && data.length > 4) {
          this.phone = +data.substring(2)
        }

        if (data.toString().startsWith('08') && data.length > 4) {
          this.phone = +data.substring(1)
        }
      },
    },
  },
  methods: {
    startLogin() {
      this.$refs.loginValidation.validate().then(success => {
        if (success && !this.loading) {
          this.loading = true
          this.$store.dispatch('login', {
            phone: `62${this.phone}`,
            password: this.password,
          }).then(() => {
            this.loading = false
            this.$store.dispatch('initApp')

            if (this.$store.state.app.nextRoute.fullPath) {
              this.$router.push({ path: this.$store.state.app.nextRoute.fullPath })
            } else {
              this.$router.push({ name: 'home' })
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login berhasil!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            this.loading = false
            this.status = 'Nomor Telepon / Password Salah'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login gagal!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
